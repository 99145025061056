const conditionResolvers = {
  AND: (left, right) => left && right,
  OR: (left, right) => left || right,
}
const ruleResolvers = {
  equal: (conditionValue, fieldValue) => conditionValue == fieldValue,
  not_equal: (conditionValue, fieldValue) => conditionValue != fieldValue,
  contains: (conditionValue, fieldValue) => fieldValue.includes(conditionValue),
  not_contains: (conditionValue, fieldValue) =>
    !fieldValue.includes(conditionValue),
  begins_with: (conditionValue, fieldValue) =>
    fieldValue.startsWith(conditionValue),
  ends_with: (conditionValue, fieldValue) =>
    fieldValue.endsWith(conditionValue),
  greater: (conditionValue, fieldValue) =>
    parseFloat(fieldValue) > conditionValue,
  greater_or_equal: (conditionValue, fieldValue) =>
    parseFloat(fieldValue) >= conditionValue,
  less: (conditionValue, fieldValue) => parseFloat(fieldValue) < conditionValue,
  less_or_equal: (conditionValue, fieldValue) =>
    parseFloat(fieldValue) <= conditionValue,
}

export const conditionsSatisfied = (condition, fieldValue) => {
  if (condition.operator) {
    return resolveRule(condition, fieldValue(condition.id))
  } else {
    const resolvedRules = condition.rules.map((condition) =>
      conditionsSatisfied(condition, fieldValue)
    )
    const resolver = conditionResolvers[condition.condition]

    if (resolver) {
      return resolvedRules.reduce(resolver)
    } else {
      // log missing resolver
      console.error('Missing condition resolver: ', condition.condition)
      return true
    }
  }
}

const resolveRule = (condition, fieldValue) => {
  const resolver = ruleResolvers[condition.operator]

  if (resolver) {
    return resolver(condition.value, fieldValue)
  } else {
    // log missing resolver
    console.error('Missing rule resolver: ', condition.operator)
    return true
  }
}
